<template>
  <div class="v_container_index" id="screen">
    <div class="sub_container_index">
      <!-- 顶部标题 -->
      <div class="v-title">
        <div class="v-title-inner">
          <div class="v-title-left">
            {{ nowTime }}
          </div>
          <div class="v-title-center">
            <div id="test" class="test" :class="{ editing: isChecked }">
              <div class="view">
                <label @dblclick="dbTest()">{{ TitleName }}</label>
              </div>
              <input
                v-myfoucs="isChecked"
                class="edit"
                type="text"
                v-model="inputStr"
                @blur="inputStred()"
                @keyup.13="inputStred()"
              />
            </div>
          </div>
          <div class="v-title-right">
            <div class="v_title_map">
              <div @click="isshowMap(true)" v-show="isShowMap">隐藏地图</div>
              <div @click="isshowMap(false)" v-show="!isShowMap">展开地图</div>
            </div>
            <div class="v_title_Field">
              <div @click="isshowField(true)" v-show="isShowField">
                隐藏案场
              </div>
              <div @click="isshowField(false)" v-show="!isShowField">
                展开案场
              </div>
            </div>
            <div class="v_title_FUll" v-show="!isFull" @click="MapZoom()">
              <!-- 全屏 -->
              <img
                src="../../assets/images/site_img/fullscreen_img.png"
                alt=""
                class="fullscreen_img"
              />
            </div>
            <div class="v_title_FUll" v-show="isFull" @click="MapZoom_Exit()">
              <!-- 退出全屏 -->
              <img
                :src="
                  require('../../assets/images/site_img/fullscreen_exit_img.png')
                "
                alt=""
                class="fullscreen_exit_img"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 中间 -->
      <div class="v-container-data">
        <div :class="isHaveMap" v-if="isShowMap">
          <Map v-if="isShowMap" :showDrawer="showDrawer" @record="RecordSon" />
        </div>
        <div :class="isHaveField" v-if="isShowField">
          <SingleScreen v-if="isShowField" />
        </div>
      </div>

      <a-drawer
        placement="right"
        :width="800"
        :closable="true"
        :visible="visible"
        @close="onClose"
      >
        <!-- :after-visible-change="afterVisibleChange" -->

        <SitePreview
          :record="record"
          :SiteList_previer_true="true"
          ref="SitePreview"
          @JumpSubpage="JumpSubpage"
          v-if="SitePreview"
        />
        <SiteParticulars
          :record="record"
          v-if="SiteParticulars"
          ref="SiteParticulars"
        />
        <SitePlayback
          :record="record"
          v-if="SitePlayback"
          ref="SitePlayback"
        ></SitePlayback>
      </a-drawer>
    </div>
  </div>
</template>

<script>
import Map from "../adminBoard/Map.vue";
import SingleScreen from "./SingleScreen";
import screenfull from "screenfull";
import SitePreview from "../site/SitePreview.vue";
import SiteParticulars from "../site/SiteParticulars.vue";
import SitePlayback from "../site/SitePlayback.vue";
export default {
  name: "MapFieldboard",
  components: {
    Map,
    SingleScreen,
    SitePreview,
    SiteParticulars,
    SitePlayback,
  },
  data() {
    return {
      nowTime: "", // 当前日期时间
      isShowMap: true,
      isShowField: true,
      isHaveMap: "left_map",
      isHaveField: "right_Field",
      TitleName: "可视化案场",
      isChecked: false,
      inputStr: "",
      isFull: false, //全屏检查
      visible: false,
      SiteParticulars: false,
      SitePlayback: false,
      SitePreview: false,
      record: {},
    };
  },
  created() {
    this.getTime();
    window.onresize = () => {
      //调用判断全屏的方法，用来监听
      this.checkFull();
    };
  },
  mounted() {
    // 如果本地存的有标题，就用本地存的标题
    if (localStorage.getItem("MapFieldView_TitleName")) {
      this.TitleName = localStorage.getItem("MapFieldView_TitleName");
    }
  },
  watch: {},
  methods: {
    // 获取当前时间
    getTime() {
      this.timer = setInterval(() => {
        let timeDate = new Date();
        let year = timeDate.getFullYear();
        let mounth = timeDate.getMonth() + 1;
        mounth = mounth >= 10 ? mounth : "0" + mounth;
        let day = timeDate.getDate();
        day = day >= 10 ? day : "0" + day;
        let hours = timeDate.getHours();
        hours = hours >= 10 ? hours : "0" + hours;
        let minutes = timeDate.getMinutes();
        minutes = minutes >= 10 ? minutes : "0" + minutes;
        let seconds = timeDate.getSeconds();
        seconds = seconds >= 10 ? seconds : "0" + seconds;
        let week = timeDate.getDay();
        let weekArr = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ];
        this.nowTime = `${year}-${mounth}-${day} ${hours}:${minutes}:${seconds} ${weekArr[week]}`;
      }, 1000);
    },
    isshowMap(Boolean) {
      // this.isShowMap = !Boolean;
      // 如果点击折叠地图
      if (Boolean == true) {
        this.isHaveMap = "NotHaveMap"; //地图宽度为0
        this.isHaveField = "AllField"; //案场宽度为100%
        this.isShowMap = false; //显示"展开地图"
      } else {
        // 如果点击展开地图
        this.isShowMap = true; //显示"折叠地图"
        // 如果案场是展开状态
        if (this.isShowField == true) {
          this.isHaveMap = "left_map"; //地图宽度为50%
          this.isHaveField = "right_Field"; //案场宽度为50%
        } else {
          // 如果案场是折叠状态
          this.isHaveMap = "AllMap"; //地图宽度为100%
          this.isHaveField = "NotHaveField"; //案场宽度为50%
        }
      }
    },
    isshowField(Boolean) {
      // 如果点击折叠案场
      if (Boolean == true) {
        // 地图需要重新渲染，不然右侧图标点击失效
        this.isShowMap = false;
        setTimeout(() => {
          this.isShowMap = true;
        });
        this.isHaveMap = "AllMap"; //地图宽度为100%
        this.isHaveField = "NotHaveField"; //案场宽度为0
        this.isShowField = false; //显示"展开案场"
      } else {
        // 如果点击展开案场
        this.isShowField = true; //显示"折叠案场"
        // 如果地图是展开状态
        if (this.isShowMap == true) {
          this.isHaveMap = "left_map"; //地图宽度为50%
          this.isHaveField = "right_Field"; //案场宽度为50%
        } else {
          // 如果地图是折叠状态
          this.isHaveMap = "NotHaveMap"; //地图宽度为0
          this.isHaveField = "AllField"; //案场宽度为100%
        }
      }
    },
    // 双击标题进行编辑
    dbTest() {
      this.isChecked = true;
      this.inputStr = this.TitleName;
    },
    inputStred() {
      this.TitleName = this.inputStr;
      // 编辑标题后保存到本地，下次打开页面还会存在
      localStorage.setItem("MapFieldView_TitleName", this.TitleName);
      this.isChecked = false;
    },
    // 全屏
    MapZoom() {
      let element = document.getElementById("screen"); //指定全屏区域元素
      // let status_con = document.getElementById("status-con");
      let exit_icon = document.getElementById("exit_icon");
      screenfull.toggle(element); //全屏显示
      // status_con.style.display = "none";
      exit_icon.style.display = "block";
      console.log("全屏");
    },
    checkFull() {
      let isFull = window.fullScreen || document.webkitIsFullScreen;
      this.isFull = isFull;
    },
    // 退出全屏按钮
    MapZoom_Exit() {
      this.isFull = false;
      console.log("点击了MapZoom_Exit", this.isFull);
      let element = document.getElementById("screen"); //指定全屏区域元素
      screenfull.exit(element); //退出全屏
    },
    showDrawer(ref) {
      this.visible = true;
      if (ref == "SiteParticulars") {
        this.SiteParticulars = true;
        this.SitePreview = false;
        this.SitePlayback = false;
      } else if (ref == "SitePreview") {
        this.SitePreview = true;
        this.SiteParticulars = false;
        this.SitePlayback = false;
      } else if (ref == "SitePlayback") {
        this.SitePreview = false;
        this.SiteParticulars = false;
        this.SitePlayback = true;
      }
    },
    JumpSubpage() {
      this.SitePreview = false;
      this.SiteParticulars = false;
      this.SitePlayback = true;
    },
    onClose() {
      this.visible = false;
      this.SiteParticulars = false;
      this.SitePreview = false;
      this.SitePlayback = false;
      this.$refs.SitePreview.close();
    },
    RecordSon(data) {
      this.record = data;
      this.ws_id = data.ws_id;
      this.ws_name = data.ws_name;
      // console.log(data.ws_id);
    },
  },
  // 指令
  directives: {
    myfoucs: {
      //自定义myfoucs指令
      update(el, binding) {
        if (binding.value) {
          el.focus();
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
.v_container_index {
  background: #091423;
  width: 100%;
  height: 100%;
  .sub_container_index {
    height: 100%;
    width: 100%;
    // position: relative;
  }
  .v-title {
    position: relative;
    color: #66ffff;
    height: 5%;
    //   line-height: 75px;
    //   line-height: 100%;
    //   display: flex;

    font-size: 27px;
    background: url("../../assets/images/visualize/title_inspection.png")
      no-repeat;
    background-position: center center;
    background-size: auto 100%;

    .v-title-left {
      position: absolute;
      left: 5%;
      color: #fff;
      font-size: 14px;
      // line-height: 60px;
      top: 25%;
    }
    //   分公司选择框
    .v-title-left2 {
      position: absolute;
      left: 20%;
      color: #fff;
      font-size: 14px;
      // line-height: 60px;
      // top: -8%;
      top: 20%;
    }
    .v-title-center {
      position: absolute;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      top: 20%;
      left: 46%;
    }
    .v-title-right {
      position: absolute;
      right: 3%;
      top: 0;
      color: #fff;
      font-size: 18px;
      // line-height: 60px;
      top: 20%;
      font-family: SimHei;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      .v_title_map,
      .v_title_Field,
      .v_title_FUll {
        padding: 10px;
        cursor: pointer;
        &:hover {
          color: #66ffff;
        }
      }
      .v_title_FUll {
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
  .test.editing .edit {
    display: block;
    width: 200px;
    margin-left: 30%;
  }
  .test.editing .edit {
    display: block;
    width: 200px;
    margin-left: 30%;
  }

  .test.editing .view {
    display: none;
  }
  .test .edit {
    display: none;
    background-color: #062a49;
  }
}
.v-container-data {
  height: 94%;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.left_map {
  width: 39%;
  // height: 100%;
  height: 98%;
  margin-top: 1%;
}
.right_Field {
  width: 59%;
  height: 99%;
}
.NotHaveMap {
  width: 0;
}
.NotHaveField {
  width: 0;
}
.AllMap {
  width: 90%;
  height: 100%;
}
.AllField {
  width: 90%;
  height: 100%;
}
/deep/.control_left {
  margin-bottom: 1%;
}
/deep/ .myVideo_out {
  width: 93% !important;
}
</style>