<template>
  <div class="map_field">
    <div class="sub_container">
      <!-- 数据 -->
      <div v-show="isShow" style="height: 100%">
        <!-- <div> -->
        <div style="display: flex; margin-top: 2%; height: 100%">
          <div style="flex: 1; padding-right: 10px; width: 80%">
            <!--直播 -->
            <!--:pageType="'patrol' " || 'caseField' -->
            <div class="sitePpreview" v-if="isShowPrew">
              <SitePreview
                ref="site_preview"
                :record="record"
                :pageType="'caseField' && 'patrol'"
                :key="componentKey"
                :pageTypecaseField="pageTypecaseField"
              ></SitePreview>
            </div>
            <div v-if="!isShowPrew" class="video_out">
              <!-- muted：视频的音频输出为静音 loop循环播放 autoplay自动播放-->
              <video
                class="video"
                id="videoID"
                v-if="videoUrl != ''"
                controls
                autoplay
                preload="auto"
                :src="videoUrl"
                type="video/mp4"
                muted
                style="background: black; object-fit: cover"
              ></video>
            </div>
            <div class="bianji_upload swiper-container">
              <div class="bianji_img swiper-wrapper">
                <div
                  class="info_out swiper-slide"
                  v-for="item in photoList"
                  :key="item.id"
                >
                  <div class="info">
                    <div class="actions">
                      <div v-if="item.src != ''" class="icon_out">
                        <img :src="item.src" href="javascript:;" />
                        <div class="icon">
                          <a-tooltip placement="top">
                            <template slot="title">
                              <span>播放此视频</span>
                            </template>
                            <a-icon
                              type="eye"
                              class="a_icon"
                              @click="changeVideo(item)"
                            />
                          </a-tooltip>

                          <a-popconfirm
                            title="是否确认删除视频"
                            ok-text="是"
                            cancel-text="否"
                            @confirm="confirmDelet(item)"
                            @cancel="cancelDelet(item)"
                          >
                            <a-tooltip placement="top">
                              <template slot="title">
                                <span>删除此视频</span>
                              </template>
                              <a-icon
                                type="delete"
                                class="a_icon delete_icon"
                              />
                            </a-tooltip>
                          </a-popconfirm>
                        </div>
                      </div>
                      <div v-else>暂无快照</div>
                    </div>
                    <div class="imageName">
                      <a-tooltip>
                        <template slot="title">
                          {{ item.name || item.videoName }}
                        </template>
                        {{ item.name || item.videoName }}
                      </a-tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
          <div class="v-msg">
            <div style="height: 90%">
              <div class="caseBTN">
                <!-- 自动播放点击事件 -->
                <div>
                  <span class="bofangBtn"
                    ><span v-if="startShow" @click="autoplay('start')"
                      >开启自动播放</span
                    ><span v-if="closedShow" @click="autoplay('close')"
                      >关闭自动播放</span
                    ></span
                  >
                </div>
                <div class="v-title-right">
                  <!-- 设置按钮 -->
                  <case-set
                    ref="CaseSet"
                    @search="onsearch"
                    @ok="stopZhiBo"
                  ></case-set>
                  <!-- <span @click=""><i class="iconfont icon-shezhi" /> 设置</span> -->
                  <!-- <span @click="jumpBack"><a-icon type="logout" /> 退出</span> -->
                </div>
              </div>
              <a-row>
                <a-col :span="12" v-for="(item, i) in plainOptions" :key="i">
                  <img
                    :id="'videoImg' + i"
                    :src="item.label"
                    :alt="item.value"
                    class="check-img"
                    @click="siteChange(i)"
                  />
                </a-col>
              </a-row>
            </div>
            <a-pagination
              class="v-page"
              v-model="current"
              :default-current="1"
              :total="total"
              :page-size="12"
              :show-total="(total) => `共 ${total} 条`"
              @change="onChangeDate"
              show-less-items
              show-quick-jumper
            />
            <div class="clearfix">
              <div class="fileUpload">
                <label class="custom-file-upload">
                  <div class="flex_center">
                    <span>导入视频</span>
                  </div>
                  <input
                    type="file"
                    id="fileInput"
                    accept="video/*"
                    ref="input"
                    @change="updateVideo"
                  />
                </label>
              </div>
              <div class="restore_default_settings" @click="resetButton">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>恢复默认视频设置</span>
                  </template>
                  <div class="button">重置</div>
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 没有样板工地时显示 -->
      <div v-show="isNoCaseShow">
        <no-case @changeList="changeList"></no-case>
      </div>
    </div>

    <a-modal
      class="transfer-modal"
      title="轮播列表"
      okText="确定"
      cancelText="取消"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="setOk"
      @cancel="setCancel"
    >
      <a-transfer
        class="site-trans"
        :data-source="mockData"
        show-search
        :titles="titles"
        :filter-option="filterOption"
        :target-keys="targetKeys"
        :render="(item) => item.title"
        @change="handleChange"
        @search="handleSearch"
      />
    </a-modal>
  </div>
</template>
<script>
import SitePreview from "./SitePreview.vue";
import { GetScreenWorkList } from "../../api/screen.js"; //查询角色信息列表
import LiveBroadcast from "../patrol/LiveBroadcast.vue";
import PatrolCarousel from "../caseField/PatrolCarousel.vue";
import screenfull from "screenfull";
import { GetScreenWorksiteNewImage } from "@/api/screen"; //可视化案场获取工地最新快照
import { requestImage } from "@/utils/fileRequest";
import { urlConfig } from "@/config/config";
// 引入设置按钮弹窗
import CaseSet from "../caseField/CaseSet.vue";
import NoCase from "../caseField/NoCase.vue";
import { IndexDBCache } from "./IndexDB.js";
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
// 根据项目实际需求，设置对应数据库名、表名和数据库主键（主键需要为添加对象内的key，否则新增和获取会失败）
const params = {
  dbName: "IndexDB_viedo",
  cacheTableName: "imageCache",
  keyPath: "videoName",
  indexs: [
    { name: "videoData", unique: false },
    { name: "imageFile", unique: true },
  ],
};
let imageDB = new IndexDBCache(params);

export default {
  name: "SingleScreen",
  components: { SitePreview, LiveBroadcast, PatrolCarousel, CaseSet, NoCase },
  data() {
    return {
      isFullFlag: false,
      current: 1,
      total: 0,
      componentKey: 50,
      queryParam: {
        ws_id: "",
        // s_time: "1663663074",
        bc_id: "",
        pageNo: 2,
        pageSize: 12,
      },
      // 开启、关闭自动播放
      startShow: true,
      closedShow: false,
      // NoCase是否显示
      isShow: true,
      isNoCaseShow: false,
      ws_id: "",
      bc_id: "",
      nowTime: "", // 当前日期时间
      screenCase: [],
      pictureList: [],
      visible: false,
      confirmLoading: false,
      // transfer
      titles: ["工地列表", "已选工地"],
      mockData: [],
      targetKeys: [],
      //赋值链表
      listTemp: [
        { description: "", title: "" },
        { description: "", title: "" },
        { description: "", title: "" },
        { description: "", title: "" },
        { description: "", title: "" },
        { description: "", title: "" },
      ],
      //巡查展示判断
      listShow: {
        show0: true,
        show1: true,
        show2: true,
        show3: true,
        show4: true,
        show5: true,
      },
      //次数
      playnum: 0,
      plainOptions: [], // 所有的选项
      record: {
        ws_name: "",
        ws_id: "",
        w_power: "",
      },
      // 图片地址
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      pageTypecaseField: "2",
      timer: null,
      previewVisible: false,
      previewImage: "",
      isShowPrew: false,
      videoUrl: "",
      fileList: [],
      photoList: [
        {
          id: 1,
          name: "为什么选择业之峰",
          src: require("../../assets/video/video1.png"),
          videoSrc: require("../../assets/video/video1.mp4"),
        },
        {
          id: 2,
          name: "装修中哪些插座必不可少",
          src: require("../../assets/video/video2.png"),
          videoSrc: require("../../assets/video/video2.mp4"),
        },

        {
          id: 3,
          name: "业之峰蓝砖环保工程",
          src: require("../../assets/video/video3.png"),
          videoSrc: require("../../assets/video/video3.mp4"),
        },
        {
          id: 4,
          name: "室内门应该如何选择",
          src: require("../../assets/video/video4.png"),
          videoSrc: require("../../assets/video/video4.mp4"),
        },
        {
          id: 5,
          name: "业之峰专用水管管件含铜量测试",
          src: require("../../assets/video/video5.png"),
          videoSrc: require("../../assets/video/video5.mp4"),
        },
        {
          id: 6,
          name: "水电改造如何验收",
          src: require("../../assets/video/video6.png"),
          videoSrc: require("../../assets/video/video6.mp4"),
        },
      ],
      index: 0,
    };
  },
  created() {
    this.queryParam.ws_id = this.record.ws_id;
    this.onChangeDate();
    this.initIndexDB();
  },
  mounted() {
    this.getTime();
    this.fetchData();
    this.fetchScreenSite("init");
    this.autoSite(true);
    // 进入页面关闭自动播放  关闭轮播
    clearInterval(this.siteTimer); // 关闭定时器
    // this.startShow = false;
    // this.closedShow = true;
    // localStorage.setItem("photoList", JSON.stringify(this.photoList));
    this.changeVideo(this.photoList[0]);
    if (localStorage.getItem("photoList")) {
      let photoList = JSON.parse(localStorage.getItem("photoList"));
      this.photoList = photoList;
      this.changeVideo(this.photoList[0]);
      let that = this;
      setTimeout(() => {
        var elevideo = document.getElementById("videoID");
        // 监听视频播放结束
        elevideo.addEventListener("ended", function () {
          console.log("end", that.index);
          if (that.index == that.photoList.length - 1) {
            that.index = 0;
          } else {
            that.index = that.index + 1;
          }
        });
        this.photoList.forEach((item) => {
          if (item.name == "photo1") {
            this.resetButton();
          }
        });
      }, 20);
      this.initSwiper();
    } else {
    }
    // 删除一下最初的视频
    this.photoList.forEach((item) => {
      if (item.name == "photo1") {
        this.resetButton();
      }
    });
  },

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.siteTimer) {
      clearInterval(this.siteTimer);
    }
  },
  destroyed() {
    // 浏览器页面关闭断开视频
    window.removeEventListener("beforeunload", () =>
      this.$refs["LiveBroadcast" + 0].stopVideo()
    );
  },
  watch: {
    isShowPrew(newValue) {
      if (newValue == false) {
        // clearInterval(this.siteTimer);
        // this.closedShow = true;
        // this.startShow = false;
      } else {
        // this.autoSite();
        // this.closedShow = false;
        // this.startShow = true;
      }
    },
    index(newValue) {
      let video = this.photoList[newValue];
      if (video.videoSrc) {
        this.videoUrl = video.videoSrc;
        console.log("111", newValue);
      } else {
        this.getImageByName(video.videoName); //根据videoName查询数据
        console.log("222", newValue);
      }
    },
    photoList(newVal) {
      console.log("数据改变", newVal);
      // 数据改变要初始化轮播  还没完成
      setTimeout(() => {
        this.initSwiper();
      }, 10);
    },
    immediate: true,
  },
  methods: {
    stopZhiBo() {
      // console.log("99");
      // 调用直播页面的关闭视频的方法
      this.$refs.site_preview.close();
    },

    clickFun() {
      this.isFullFlag = !this.isFullFlag;
      screenfull.toggle();
    },
    // 自动播放点击事件
    autoplay(type) {
      this.isShowPrew = true;
      // console.log(type);
      if (type == "close") {
        console.log("close", type);
        clearInterval(this.siteTimer); // 关闭定时器
        this.startShow = true;
        this.closedShow = false;
      } else if (type == "start") {
        console.log("start", type);
        this.autoSite();
        this.startShow = false;
        this.closedShow = true;
      }
      // console.log("startShow", this.startShow);
      // console.log("closedShow", this.closedShow);
    },

    autoSite(isFirst) {
      if (isFirst) {
        // console.log("是否第一次：", isFirst);
        this.playList = [];
        this.videoIndex = 0;
        this.currentPage = 1;
        this.getVideoId("1").then(() => {
          this.record.ws_id = this.playList[0] ? this.playList[0] : null;
          setTimeout(() => {
            this.componentKey = 0;
            this.record.ws_id = this.playList[0] ? this.playList[0] : null;
            // this.record.ws_id = this.plainOptions[0] ? this.plainOptions[0].ws_id : null;
            const videoImg = document.getElementById("videoImg0");
            // 有bug
            videoImg.style.border = "solid #04c2d5";
          }, 500);
        });
      } else {
        // console.log("点击之后：", this.videoIndex);
      }
      this.siteTimer = setInterval(() => {
        this.videoIndex++;
        // console.log(this.playList[this.componentKey], this.componentKey)
        if (this.playList[this.videoIndex]) {
          const videoImg = document.getElementById(
            "videoImg" + String(this.videoIndex - 1)
          );
          if (videoImg) {
            // console.log("清除第" + (this.videoIndex - 1) + "个边框");
            videoImg.style.border = "";
          }
          this.componentKey = this.videoIndex;
          this.record.ws_id = this.playList[this.videoIndex]
            ? this.playList[this.videoIndex]
            : null;
          console.log("正在播放第" + this.videoIndex + "个");
          if (this.currentPage === this.current) {
            const videoImg = document.getElementById(
              "videoImg" + this.componentKey
            );
            if (videoImg) {
              videoImg.style.border = "solid #04c2d5";
            }
          }
        } else {
          this.videoIndex = 0;
          this.playList = [];
          const pageNum =
            this.total % this.queryParam.pageSize === 0
              ? this.total / this.queryParam.pageSize
              : this.total / this.queryParam.pageSize + 1;
          if (this.currentPage === this.current) {
            if (this.current === pageNum) {
              this.current = 1;
            } else {
              this.current = this.current + 1;
            }
            this.onChangeDate();
          }
          this.currentPage = this.currentPage + 1;
          // 已经翻页了
          if (this.currentPage <= pageNum) {
            // 不是最后一页的时候
            this.getVideoId(String(this.currentPage)).then(() => {
              this.componentKey = 0;
              this.record.ws_id = this.playList[0] ? this.playList[0] : null;
              console.log("播放第0个");
              const videoImg = document.getElementById("videoImg0");
              videoImg.style.border = "solid #04c2d5";
            });
          } else {
            // 最后一页，跳转第一页
            this.currentPage = 1;
            this.getVideoId("1").then(() => {
              this.componentKey = 0;
              this.record.ws_id = this.playList[0] ? this.playList[0] : null;
              const videoImg = document.getElementById("videoImg0");
              videoImg.style.border = "solid #04c2d5";
            });
          }
        }
        // }, 1000 * 60 * 1); //播放时间
      }, 1000 * 20);
    },
    getTime() {
      this.timer = setInterval(() => {
        let timeDate = new Date();
        let year = timeDate.getFullYear();
        let mounth = timeDate.getMonth() + 1;
        mounth = mounth >= 10 ? mounth : "0" + mounth;
        let day = timeDate.getDate();
        day = day >= 10 ? day : "0" + day;
        let hours = timeDate.getHours();
        hours = hours >= 10 ? hours : "0" + hours;
        let minutes = timeDate.getMinutes();
        minutes = minutes >= 10 ? minutes : "0" + minutes;
        let seconds = timeDate.getSeconds();
        seconds = seconds >= 10 ? seconds : "0" + seconds;
        let week = timeDate.getDay();
        let weekArr = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ];
        this.nowTime = `${year}-${mounth}-${day} ${hours}:${minutes}:${seconds} ${weekArr[week]}`;
      }, 1000);
    },
    // 分页
    onChange(pageNo, size) {
      this.$refs.vSearch.changePageSearch(pageNo);
    },
    changeList(result, page, sum) {
      this.list = result;
      this.current = page;
      this.total = sum;
    },
    siteChange(index) {
      if (this.siteTimer) {
        clearInterval(this.siteTimer);
        this.siteTimer = null;
      }
      this.videoIndex = index;
      this.autoSite(false);
      this.currentPage = this.current;
      this.getVideoId(this.current);
      this.componentKey = index;
      this.record.ws_id = this.plainOptions[index]
        ? this.plainOptions[index].ws_id
        : null;
      this.clearVideoImgBorder();
      const videoImg = document.getElementById("videoImg" + index);
      videoImg.style.border = "solid #04c2d5";
      // 播放直播  隐藏视频   打开自动播放
      this.isShowPrew = true;
      this.startShow = false;
      this.closedShow = true;
      // this.startShow = true;
      // this.closedShow = false;
    },
    clearVideoImgBorder() {
      for (let i = 0; i < this.plainOptions.length; i++) {
        const tmp = document.getElementById("videoImg" + i);
        if (tmp) {
          tmp.style.border = "";
        }
      }
    },
    onChangeDate(date, dateString) {
      let dateS = dateString ? dateString : new Date();
      let dates = new Date(dateS).getTime() / 1000; // 秒
      // this.queryParam.s_time = parseInt(dates);
      // console.log(this.current);
      this.clearVideoImgBorder();
      if (this.current === this.currentPage) {
        const videoImg = document.getElementById("videoImg" + this.videoIndex);
        videoImg.style.border = "solid #04c2d5";
      }
      this.queryParam.pageNo = this.current;
      // this.queryParam.pageSize = this.current;
      this.fetchSnapshot();
    },
    //请求侧边栏接口
    fetchSnapshot() {
      //请求侧边栏接口
      // this.pageNo = queryParam.pageNo
      // this.pageSize = queryParam.pageSize
      let order = ["bc_id", "pageNo", "pageSize"];
      const params = {
        ...this.queryParam,
        verify: this.$verify(this.queryParam, order),
      };
      // console.log(params);
      // 可视化案场获取工地最新的快照
      // alert(0)
      GetScreenWorksiteNewImage(params).then((res) => {
        this.current = res.data.page;

        this.total = res.data.sum;
        // 处理数据
        const url = this.pathUrl;
        let data = res.data.data;
        let list = [];
        data.forEach((el, i) => {
          // console.log(i, el.ws_id)
          list[i] = {
            value: i,
            label: require("../../assets/images/imgloading.gif"),
            ws_id: el.ws_id,
            bc_id: el.bc_id,
          };
          if (el.s_url.length > 0) {
            requestImage(url + el.s_url, function (resurl) {
              list[i].label = resurl;
            });
          }
        });

        this.plainOptions = list;
        // console.log(this.plainOptions.length);
        // 控制是否显示NoCase页面
        if (this.plainOptions == 0 && this.photoList.length == 0) {
          this.isShow = false;
          this.isNoCaseShow = true;
        } else {
          this.isShow = true;
          this.isNoCaseShow = false;
        }
      });
      // console.log("fetchSnapshot" + this.plainOptions);
    },

    changeList(result, page, sum) {
      this.plainOptions = result;
      this.current = page;
      this.total = sum;
    },
    // 子组件传过来的bc_id
    onsearch(id) {
      this.queryParam.bc_id = id ? id : "";
      console.log(this.queryParam);

      // this.bc_id = id ? id : "";
      this.fetchSnapshot();
      // this.getVideoId();
      // console.log(this.bc_id);
    },

    getVideoId(pageNo) {
      // this.pageNo = queryParam.pageNo
      // this.pageSize = queryParam.pageSize
      let order = ["bc_id", "pageNo", "pageSize"];

      const params = {
        ws_id: "",
        // s_time: "1663663074",
        // bc_id: this.bc_id,
        bc_id: "",
        pageNo: pageNo,
        pageSize: this.queryParam.pageSize,
        verify: this.$verify(this.queryParam, order),
      };
      return new Promise((resolve, rejec) => {
        GetScreenWorksiteNewImage(params).then((res) => {
          // 处理数据
          let data = res.data.data;
          let list = [];
          data.forEach((el, i) => {
            list[i] = el.ws_id;

            // list[i] = {
            //   ws_id: el.ws_id,
            //   bc_id: this.bc_id,
            // };
          });
          this.playList = list;

          data.forEach((el, i) => {
            this.record.w_power = el.w_power;
          });
          // console.log(this.record.w_power);
          resolve();
          // console.log(this.playList); //id
          // console.log(data);
        });
      });
    },

    //新子组件加载
    newAssembly(arr, len) {
      let timeplay = 20000;
      let newArr1 = [].concat(arr);
      let newArr2 = [].concat(arr);

      if (this.playnum == len) this.playnum = 0;
      //前三个窗口直播，后三个窗口快照
      if (this.playnum <= 2) {
        //视频断开
        this.$refs["LiveBroadcast" + this.playnum].stopVideo();
        this.listShow["show" + this.playnum] = false;
        console.log("执行次数" + this.playnum);
        //视频赋值
        this.listTemp[this.playnum] = newArr1[0];
        this.$refs["LiveBroadcast" + this.playnum].propsData(newArr1[0]);
        //视频开始
        this.$refs["LiveBroadcast" + this.playnum].startVideo();
        this.listShow["show" + this.playnum] = true;
        newArr2.push(newArr2.shift());

        console.log("执行结束");
      } else {
        //快照更换
        this.listShow["show" + this.playnum] = false;

        console.log("执行次数" + this.playnum);
        //快照赋值
        this.listTemp[this.playnum] = newArr1[0];
        this.$refs["LiveBroadcast" + this.playnum].propsData(newArr1[0]);
        //快照开始
        this.$refs["LiveBroadcast" + this.playnum].startPatrol();
        this.listShow["show" + this.playnum] = true;
        newArr2.push(newArr2.shift());

        console.log("执行结束");
      }
      this.playnum++;
      // if(this.playnum!=6)
      if (this.playnum == len && len != 6) {
        setTimeout(() => {
          this.newAssembly(newArr2, newArr2.length - 1);
        }, (6 - len) * timeplay);
      } else {
        setTimeout(() => {
          this.newAssembly(newArr2, newArr2.length - 1);
        }, timeplay);
      }
    },
    jumpBack() {
      this.$router.push({ path: "SampleSite" });
    },
    // 初始化
    fetchScreenSite(init) {
      const targetKeys = [];
      this.screenCase = JSON.parse(localStorage.getItem("screenCase")) || [];
      if (init == "init") {
        if (this.screenCase.length > 6) {
          this.newAssembly(this.screenCase, 6);
        } else if (this.screenCase.length > 1) {
          this.newAssembly(this.screenCase, this.screenCase.length - 1);
        }
      }
      this.screenCase.forEach((el) => {
        targetKeys.push(el.key);
      });
      this.targetKeys = targetKeys;
    },
    handleSet() {
      this.visible = true;
      this.fetchScreenSite();
    },
    setOk() {
      const mockData = this.mockData;
      const list = [];
      mockData.forEach((el) => {
        if (this.targetKeys.indexOf(el.key) > -1) {
          list.push(el);
        }
      });
      localStorage.setItem("screenCase", JSON.stringify(list));
      this.visible = false;
      this.fetchScreenSite();
    },
    setCancel() {
      this.visible = false;
    },
    // transfer
    // transfer
    fetchData() {
      GetScreenWorkList().then((res) => {
        const mockData = [];
        res.data.forEach((el) => {
          const data = {
            key: el.ws_id,
            title: el.ws_name,
            description: el.ws_area,
            chosen: false,
          };
          mockData.push(data);
        });
        this.mockData = mockData;
      });
    },
    filterOption(inputValue, option) {
      // console.log("1");
      return option.title.indexOf(inputValue) > -1;
    },
    handleChange(targetKeys, direction, moveKeys) {
      console.log(targetKeys, direction, moveKeys);
      this.targetKeys = targetKeys;
    },
    handleSearch(dir, value) {
      console.log("search:", dir, value);
    },

    // 点击图片更改播放视频
    changeVideo(video) {
      // this.videoUrl = video.videoSrc;
      let that = this;

      // 获取播放视频的下标
      this.photoList.forEach((item, index) => {
        if (item.src == video.src) {
          this.index = index;
          // console.log(this.index);
        }
        // console.log(item.src);
      });

      // console.log("更改播放视频", video.src);
      if (video.videoSrc) {
        this.videoUrl = video.videoSrc;
      } else {
        this.getImageByName(video.videoName); //根据videoName查询数据
      }

      // 关闭直播，打开视频，关闭定时器
      this.isShowPrew = false;
      clearInterval(this.siteTimer);
      // this.closedShow = true;
      // this.startShow = false;
      this.closedShow = false;
      this.startShow = true;
    },
    // 删除前端储存的视频
    deleteVideo(video) {
      this.photoList.forEach((item, index) => {
        // 根据视频地址删除视频
        if (item.videoSrc == video.videoSrc) {
          this.photoList.splice(index, 1);
          if (item.videoName) {
            console.log(item);
            this.removeIndexDB(item.videoName);
          }
        }
      });
      localStorage.setItem("photoList", JSON.stringify(this.photoList));
    },
    // 截取视频第一帧
    getCover(url) {
      return new Promise((resolve) => {
        const video = document.createElement("video");
        video.autoplay = "autoplay";
        video.src = url;
        video.onplay = function () {
          window.URL.revokeObjectURL(video.src);
          const canvas = document.createElement("canvas");
          canvas.width = this.videoWidth;
          canvas.height = this.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
          const base64 = canvas.toDataURL("image/png");
          resolve(base64);
        };
      });
    },
    // input框上传视频
    updateVideo(e) {
      const file = e.target.files[0] || e.dataTransfer.files[0];
      // console.log(file);
      const imgUrl = window.URL.createObjectURL(file);
      this.getCover(imgUrl).then((res) => {
        // console.log(res);//视频第一帧作为封面
        // push到循环数组里
        this.photoList.push({
          videoName: file.name,
          src: res,
        });
        // 去重
        let map = new Map();
        for (let item of this.photoList) {
          map.set(item.videoName ? item.videoName : item.videoSrc, item);
        }
        this.photoList = [...map.values()];
        console.log(this.photoList);
        localStorage.setItem("photoList", JSON.stringify(this.photoList));

        // push到展示数组的同时存到indexDB里 数据流file可以直接存到indexDB
        this.changeVal(file.name, file);
      });

      // 处理连续上传同一个视频没反应
      this.$refs.input.value = "";
    },

    // 初始化数据库
    initIndexDB() {
      imageDB
        .initDB()
        .then((res) => {
          if (res.type == "upgradeneeded") {
            console.log("indexDB 数据库创建或更新成功!");
          } else {
            console.log("indexDB 数据库初始化成功!");
          }
          // 数据库初始化成功时，
        })
        .catch((err) => {
          console.log("indexDB 数据库初始化失败! ", err);
        });
    },

    // 新增数据
    changeVal(videoName, file) {
      const data = {
        videoName: videoName,
        file: file,
      };
      imageDB
        .addData(data)
        .then((res) => {
          console.log("写入 indexDB 数据库成功", res);
          // this.updateIndexDB(videoName, file);
        })
        .catch((err) => {
          console.log("111写入 indexDB 数据库失败==>", err);
        });
    },
    // 更新数据
    updateIndexDB(videoName, file) {
      imageDB.update({
        file: file,
        videoName: videoName,
      });
    },
    // 查询数据  根据主键 name
    getImageByName(videoName) {
      imageDB
        .getDataByKey(videoName)
        .then((res) => {
          console.log("从indexDB数据库获取数据成功", res);
          res.forEach((item) => {
            if (videoName == item.videoName) {
              let URL = window.URL || window.webkitURL; // 获取当前域名地址
              this.videoUrl = URL.createObjectURL(item.file);
              console.log(this.decoding(item.file, item.videoName));
            }
          });
        })
        .catch((err) => {
          console.log("从indexDB数据库获取数据失败==>", err);
        });
    },
    // 删除数据库数据
    removeIndexDB(videoName, file) {
      imageDB.remove(videoName);
      // this.updateIndexDB(videoName, file);
    },
    // 重置 恢复默认设置
    resetButton() {
      this.photoList.forEach((item) => {
        if (item.videoName) {
          this.removeIndexDB(item.videoName);
        }
      });
      this.photoList = [
        {
          id: 1,
          name: "为什么选择业之峰",
          src: require("../../assets/video/video1.png"),
          videoSrc: require("../../assets/video/video1.mp4"),
        },
        {
          id: 2,
          name: "装修中哪些插座必不可少",
          src: require("../../assets/video/video2.png"),
          videoSrc: require("../../assets/video/video2.mp4"),
        },

        {
          id: 3,
          name: "业之峰蓝砖环保工程",
          src: require("../../assets/video/video3.png"),
          videoSrc: require("../../assets/video/video3.mp4"),
        },
        {
          id: 4,
          name: "室内门应该如何选择",
          src: require("../../assets/video/video4.png"),
          videoSrc: require("../../assets/video/video4.mp4"),
        },
        {
          id: 5,
          name: "业之峰专用水管管件含铜量测试",
          src: require("../../assets/video/video5.png"),
          videoSrc: require("../../assets/video/video5.mp4"),
        },
        {
          id: 6,
          name: "水电改造如何验收",
          src: require("../../assets/video/video6.png"),
          videoSrc: require("../../assets/video/video6.mp4"),
        },
      ];
      localStorage.setItem("photoList", JSON.stringify(this.photoList));
      // console.log(this.photoList);
    },
    confirmDelet(e) {
      this.deleteVideo(e);
      this.$message.success("视频删除成功");
    },
    cancelDelet(e) {
      this.$message.error("视频删除失败");
    },
    // 轮播图
    initSwiper() {
      console.log("初始化轮播");
      let vm = this;
      var mySwiper = new Swiper(".swiper-container", {
        autoplay: false, //可选选项，自动滑动
        loop: false, // 循环模式选项 当播放到最后一张图片后，点击按钮是否会继续轮播
        slidesPerView: 4, //一次显示4个
        slidesPerGroup: 1, //一次往前移一个
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.map_field {
  background: #0a1621;
  width: 100% !important;
  height: 99% !important;
  /deep/.sub_container {
    // min-height: 100%;
    // height: 89%;
    height: 98%;
    width: 100%;
    // background: pink;
  }
  .transfer-modal /deep/ .ant-modal {
    width: 1000px !important;
  }
  .site-trans /deep/ .ant-transfer-list {
    width: 450px;
    height: 300px;
  }
  .check-img {
    width: 100%;
    height: 110px;
    vertical-align: top;
    margin-top: 20px;
    margin-right: 0px;
    border-radius: 10px;
  }
  .enlarge-img:hover {
    color: #1890ff;
  }
  /deep/ .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    // opacity: 1;
    margin-left: -50%;
  }
  /deep/ .ant-upload-picture-card-wrapper {
    display: flex;
  }
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
  /deep/.ant-upload-list-item-card-actions .anticon {
    color: #1890ff;
  }
  /deep/ .modal-content-nobg {
    padding-right: 0;
    padding-left: 0;
  }
}
.v-box-title {
  color: #fff;
  height: 20px;
  line-height: 20px;
  font-weight: 500;
  font-size: 15px;
  text-align: left;
}
.v-box-video {
  margin-top: 20px;
  width: 100%;
  max-height: 300px;
}
.v-msg {
  width: 20%;
}
.v-page {
  margin-bottom: 20px;
  /deep/ .ant-pagination-total-text {
    color: #ccc;
  }
  /deep/ .ant-pagination-item-link {
    background-color: rgba(0, 0, 0, 0);
    color: #fff;
  }
  /deep/ .ant-pagination-item {
    background-color: rgba(0, 0, 0, 0);
    &:hover {
      border-color: #47b4ea;
    }
    a {
      color: #fff;
      &:hover {
        color: #47b4ea;
      }
    }
  }
  /deep/ .ant-pagination-item-active {
    background-color: #47b4ea;
    &:hover {
      border-color: #47b4ea;
    }
    a {
      &:hover {
        color: #fff;
      }
    }
  }
  /deep/ .ant-pagination-item-ellipsis {
    color: #fff;
  }
  /deep/ .ant-pagination-options-quick-jumper {
    color: #ccc;
    input {
      color: #47b4ea;
      background-color: rgba(0, 0, 0, 0);
    }
  }
}
.caseBTN {
  display: flex;
  color: #fff;
  cursor: pointer;
}
.bianji_upload {
  display: flex;
  justify-content: space-between;
  // width: 53vw;
  width: 100%;
  // height: 10%;
  height: 15%;
  margin-top: 10px;
  position: relative;
  // background-color: #fff;
  /deep/ .ant-upload.ant-upload-select {
    text-align: left;
  }
  /deep/ .upload-list-inline {
    width: 50%;
    background-color: blue;
  }
  /deep/ .ant-upload-list {
    display: flex;
    width: 70%;
  }
  /deep/ .ant-upload-list-picture .ant-upload-list-item {
    width: 200px;
  }
  .bianji_img {
    display: flex;
    justify-content: left;
    width: 90%;
    // background-color: pink;
    height: 100%;
    // position: relative;
    img {
      width: 100%;
      height: 100%;
      padding: 0 2%;
    }
  }
  // 鼠标悬停蒙层
  .icon_out {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    font-size: 25px;
    // left: 23%;
    // top: 40%;
    position: absolute;
    margin: auto;
    display: none;
    .a_icon {
      padding: 0 10px;
      width: 50%;
      height: 100%;
    }
    .delete_icon {
      font-size: 20px;
      margin: 50px 0 0 10px;
    }
  }
  .info {
    width: 100%;
    height: 100%;
    &:before {
      content: " ";
      position: absolute;
      z-index: 1;
      background-color: fade(rgba(3, 3, 3, 0.966), 50%);
      width: 100%;
      height: 80%;
      opacity: 0;
      margin-left: -50%;
    }
  }
  .actions {
    z-index: 10;
    opacity: 1;
    width: 100%;
    height: 80%;
  }
  .info_out {
    // width: 20%;
    // width: 100px;
    // width: 20% !important;
    height: 100% !important;
    position: relative;
    cursor: pointer;
  }
  .info:hover {
    &:before {
      opacity: 1;
      position: absolute;
    }
    .actions {
      opacity: 1;
    }
    .icon {
      display: flex;
      color: white;
      justify-content: center;
      align-items: center;
      z-index: 2;
    }
    .delete_icon {
      font-size: 20px;
      margin: 50px 0 0 20px;
    }
  }
  .info:hover + .actions,
  .actions:hover {
    opacity: 1;
  }
  .imageName {
    color: #fff;
    width: 100%;
    height: 20%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.video_out {
  // height: 100%;
  height: 90%;
  .video {
    width: 100%;
    height: 100%;
  }
}
.clearfix {
  // height: 100%;
  // width: 10%;
  width: 100%;
  height: 50px;

  display: inline-block;
  cursor: pointer;
  display: none;
  .fileUpload {
    // width: 45%;
    width: 100px;
    height: 30px;
  }
  #fileInput {
    display: none;
  }
  .custom-file-upload {
    height: 100%;
    width: 100%;
    color: white;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // background-color: #fafafa;
    background-color: #40a9ff;
    border-radius: 4px;
    .plus_icon {
      width: 100%;
      font-size: 30px;
    }
  }
  .restore_default_settings {
    // margin-top: 10px;
    // width: 45%;
    width: 100px;
    // height: 50%;
    height: 30px;
    border-radius: 4px;
    color: #fff;
    background-color: #fb4a3e;
    text-align: center;
    .button {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
// .bianji_upload:hover {
//   .clearfix {
//     // display: block;
//     // display: inline-block;
//   }
// }
.v-msg:hover {
  .clearfix {
    display: block;
    display: inline-block;
    display: flex;
    justify-content: space-around;
  }
}

.sitePpreview,
.video_out {
  height: 85%;
}
</style>
